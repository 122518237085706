import React from 'react'
import { Box, Text } from 'grommet'
import { FormattedMessage } from 'react-intl'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone, faEnvelope, faAddressBook } from '@fortawesome/free-solid-svg-icons'
import { faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons'

import { NavLink } from 'react-router-dom'

const WebsiteFooter = () => (
  <Box background="light-1" pad="medium" align="center" className="footer">
    <div className="pb-1 pt-2 text-uppercase text-dark text-center">
      <Text size="large" color="#ffc107" weight="bold">
        PROFI{' '}
      </Text>
      <Text size="large" color="#ffc107" weight="normal">
        <FormattedMessage id="website.title2" />
      </Text>
    </div>
    <Text size="small">
      <FontAwesomeIcon icon={faPhone} className="mr-2"></FontAwesomeIcon>+39 0473 201095
    </Text>
    <Text size="small">
      <FontAwesomeIcon icon={faEnvelope} className="mr-2"></FontAwesomeIcon>info@profiimmobilien.com
    </Text>
    <Text size="small" className="text-center">
      <FontAwesomeIcon icon={faAddressBook} className="mr-2"></FontAwesomeIcon>
      <FormattedMessage id="website.address"></FormattedMessage>
    </Text>
    <Box direction="row" pad="small">
      <Text size="small" className="footer-company-name">
        <FormattedMessage id="website.title" /> © 2020
      </Text>
      <NavLink to="/impressum" className="ml-1 mr-1">
        <FormattedMessage id="website.impressum.title" />
      </NavLink>
      <NavLink to="/privacy" className="ml-1 mr-1">
        <FormattedMessage id="website.privacy.title" />
      </NavLink>
    </Box>
    <Box direction="row">
      <a href="https://www.facebook.com/profiimmobilie/" target="_bank" rel="noopener noreferrer" className="ml-1 mr-1">
        <FontAwesomeIcon icon={faFacebook}></FontAwesomeIcon>
        <Text className="ml-2">Facebook</Text>
      </a>
      <a href="https://www.instagram.com/profi_Immobilien_Immobiliare/" target="_bank" rel="noopener noreferrer" className="ml-1 mr-1">
        <FontAwesomeIcon icon={faInstagram}></FontAwesomeIcon>
        <Text className="ml-2">Instagram</Text>
      </a>
    </Box>
  </Box>
)

export default WebsiteFooter
